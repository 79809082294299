import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomePage from "../views/HomePage.vue";

const titleStart = "OMOCEANS ";
const DEFAULT_TITLE = titleStart;

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    meta: { title: titleStart + " exclusive retreats" },
  },
  {
    path: "/branding",
    name: "Branding",
    meta: { title: titleStart + " branding" },
    component: () =>
      import(/* webpackChunkName: "branding" */ "../views/Branding.vue"),
  },
  {
    path: "/know-us",
    name: "KnowUs",
    meta: { title: titleStart + " Lua & Pedro" },
    component: () =>
      import(/* webpackChunkName: "branding" */ "../views/KnowUsView.vue"),
  },
  {
    path: "/retreat-list",
    name: "RetreatList",
    meta: { title: titleStart + " Group retreats" },
    component: () =>
      import(/* webpackChunkName: "branding" */ "../views/RetreatListView.vue"),
  },
  {
    path: "/calendar",
    name: "Calendar",
    meta: { title: titleStart + " Classes Calendar" },
    component: () =>
      import(/* webpackChunkName: "branding" */ "../views/CalendarView.vue"),
  },
  {
    path: "/retreat-tamraght",
    name: "RetreatTamraght",
    meta: { title: titleStart + " retreat Tamraght" },
    component: () =>
      import(
        /* webpackChunkName: "branding" */ "../views/retreats/RetreatTamraghtView.vue"
      ),
  },
  {
    path: "/retreat-agafay",
    name: "RetreatAgafay",
    meta: { title: titleStart + " retreat Agafay 2025" },
    component: () =>
      import(
        /* webpackChunkName: "branding" */ "../views/retreats/RetreatAgafayView.vue"
      ),
  },
  {
    path: "/retreat-ceara",
    name: "RetreatCeara",
    meta: { title: titleStart + " retreat Ceará 2025" },
    component: () =>
      import(
        /* webpackChunkName: "branding" */ "../views/retreats/RetreatCearaView.vue"
      ),
  },
  {
    path: "/intensive-rio",
    name: "IntensiveRio",
    meta: { title: titleStart + " intensive Acroyoga workshop Rio 2025" },
    component: () =>
      import(
        /* webpackChunkName: "branding" */ "../views/retreats/IntensiveRioView.vue"
      ),
  },
  {
    path: "/intensive-rio-pt",
    name: "IntensiveRioPT",
    meta: { title: titleStart + " Workshop Acroyoga Intensivo Rio 2025" },
    component: () =>
      import(
        /* webpackChunkName: "branding" */ "../views/retreats/IntensiveRioPTView.vue"
      ),
  },
  {
    path: "/praia-pipa-pt",
    name: "IntensivePipaPt",
    meta: { title: titleStart + " Pipa Praia Zen" },
    component: () =>
      import(
        /* webpackChunkName: "branding" */ "../views/retreats/IntensivePipaPtView.vue"
      ),
  },
  {
    path: "/gallery",
    name: "Gallery",
    meta: { title: titleStart + " gallery from past events" },
    component: () =>
      import(/* webpackChunkName: "branding" */ "../views/GalleryView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to: any) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  document.title = to.meta.title || DEFAULT_TITLE;
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
